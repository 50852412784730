import React from 'react';
import ReactDOM from 'react-dom';
// import { createRoot } from 'react-dom/client';
import 'style/style.css';
import 'style/font-awesome.css';
import Routing from './Routing';
import * as serviceWorker from 'serviceWorker';
import {Provider} from "react-redux";
import Store from "store/Store";
import ReduxToastr from 'react-redux-toastr';
import { ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingBar from 'react-redux-loading-bar';

ReactDOM.render(
	<Provider store={Store}>
		<LoadingBar
			// className={"LoadingBar"}
			style={{ zIndex: 1000, backgroundColor: '#007BFF'}}
		/>
		<ReduxToastr
			timeOut={5000}
			newestOnTop={false}
			preventDuplicates={false}
			position="top-right"
			getState={(state) => state.toastr} // This is the default
			transitionIn="fadeIn"
			transitionOut="fadeOut"
			progressBar={false}
			closeOnToastrClick={false}
		/>

		<Routing />
		<ToastContainer
			position="bottom-right"
			autoClose={5000}
			hideProgressBar
			newestOnTop={false}
			closeOnClick
			rtl={false}
			pauseOnFocusLoss
			draggable
			pauseOnHover
			theme="light"
			transition={Bounce}
		/>
	</Provider>,
  document.getElementById('root')
);
// const container = document.getElementById('root');
// const root = createRoot(container);
// root.render(
// 		<Provider store={Store}>
// 			<LoadingBar
// 				// className={"LoadingBar"}
// 				style={{zIndex: 1000, backgroundColor: '#007BFF'}}
// 			/>
// 			<ReduxToastr
// 				timeOut={5000}
// 				newestOnTop={false}
// 				preventDuplicates={false}
// 				position="top-right"
// 				getState={(state) => state.toastr} // This is the default
// 				transitionIn="fadeIn"
// 				transitionOut="fadeOut"
// 				progressBar={false}
// 				closeOnToastrClick={false}
// 			/>
// 			<Routing/>
// 		</Provider>
// );
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
