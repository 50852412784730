import React, {useEffect, useState} from "react";
import {Switch, Route, NavLink, useLocation} from 'react-router-dom';
// import * as Containers from "containers/index";
import Page404 from "components/Page404";
import TopbarContainer from "containers/TopbarContainer";
// import CustomScroll from 'react-custom-scroll';
// import Scrollbars from 'react-custom-scrollbars';
import { Scrollbars } from 'react-custom-scrollbars-2';
import SideMenu from "components/menu/SideMenu";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import CalendarContainer from "components/menu/CalendarContainer";
import * as Routers from "routers/index";
import {setCalendarSelectDateAction, sideBarToggle} from "actions/GUIActions";
import ScrollTopButton from "components/_common/ScrollTopButton";
import {DEBUG, APP_VERSION} from  "utils/config";
import {scrollTo} from "utils/func";
import cn from "classnames";
import TopbarUserinfo from "components/user/TopbarUserinfo";


const ContentContainer = (props) => {

	const {GUI, user,
		//actions
	} = props;

	const [scrollClassName, setScrollClassName] = useState('');//'menu-side-scroll'
	const [mobileCalendarShow, setMobileCalendarShow] = useState(false);
	const [mobileSearchShow, setMobileSearchShow] = useState(false);

	const sideOpen = GUI.sideBarOpen ? 'open' : '';

	const location = useLocation();

	const [mobileMenuOpen, setMobileMenuOpen] = useState('');

	// const [menuCounters, setMenuCounters] = useState({});


	setTimeout(() => {
		const hash = location.hash.substr(1);
		const element = hash != '' ? document.getElementById(hash) : null;
		if (element)
			scrollTo(element.offsetTop, true);
	}, 100);

	let contentStyle = {};
	useEffect(() => {
		setMobileMenuOpen('');
		// setMobileMenuOpen(' mobi-open');



		if (GUI.socket)
			GUI.socket.emit(
				'changeURL',
				{
					path: location.pathname + location.search + location.hash,
					calendarDates: GUI.calendarDates
				});

	},[location]);


	if (window.innerWidth > 1024) {
		if (user && user.settings[`user_${user.id}_style_content_max_width`])
			contentStyle['width'] = user.settings[`user_${user.id}_style_content_max_width`];
		else
			contentStyle['width'] = '100%';
	}


	let mainClass = null;
	const locPathArr = location.pathname.split('/');

	if (locPathArr[1])
		mainClass = locPathArr[1];


	return(
		<div className={cn("anim-show", {print: GUI.isPrint})}>
			<>
				<div className={"ms-top flx no-print " + sideOpen}>
					<div className="logo">
						<NavLink to="/">
							СКЛАД2.0
							{DEBUG ? <sup>dev</sup> : ''}
						</NavLink>

					</div>


					<div className="-l">
						<div className={cn("menu-bars", {close: mobileMenuOpen !== ''})}
							 onClick={e => {
								 setMobileMenuOpen(
									 mobileMenuOpen === '' ? ' mobi-open' : ''
								 );
							 }}
						>
							{/*вывод иконки вынесен в стили 12/01/22*/}
							<i className="fa"></i>
						</div>

						<div
							className="mobile-calendar-toggle"
							onClick={e => setMobileCalendarShow(!mobileCalendarShow)}
						>
							<i className={"fa fa-calendar" + (mobileCalendarShow ? '-times-o' : '-plus-o')}></i>
						</div>
						{
							GUI.searchAction
								? <div
									className="mobile-search-toggle"
									onClick={e => setMobileSearchShow(!mobileSearchShow)}
								>
									<i className={"fa fa-search"}></i>
								</div>
								: null
						}


					</div>


					<div className="ms-top-actions">
						{GUI.mobileTopbarActions}
					</div>
				</div>
				<div className={"menu-side js-scrolled no-print " + sideOpen + mobileMenuOpen}>

					<div className={cn("calendar-menu", {"mobile-hide": !mobileCalendarShow})}>
						<div className="menu">
							<ul className="_z ">
								<CalendarContainer />
								<li>
									<hr />
								</li>
							</ul>
						</div>
					</div>
					<div className="mobi-user">
						<TopbarUserinfo />
					</div>

					<div className="menu-wrap">
						<SideMenu setScrollClassName={setScrollClassName} user={props.user} GUI={GUI}/>
						{/*<Scrollbars*/}
						{/*	autoHide={false}*/}
						{/*	className={scrollClassName}*/}
						{/*>*/}
						{/*	<SideMenu setScrollClassName={setScrollClassName} user={props.user} GUI={GUI}/>*/}
						{/*</Scrollbars>*/}
					</div>
					<div className="menu-btm">
						{APP_VERSION}
					</div>
				</div>
			</>
			<TopbarContainer
				mobileSearchShow={mobileSearchShow}
			/>



			<main
				className={cn(mainClass,{full: !props.GUI.sideBarOpen})}

			>
				<div className="content" style={contentStyle}>
					<Switch>
						{/*<Route path="/" exact component={Containers.ConsoleContainer} />*/}
						<Route path="/" exact key={"tasks-index"} component={Routers.TasksRouter} />
						<Route path="/tasks" key={"tasks-tasks"} component={Routers.TasksRouter} />

						<Route path="/docs" component={Routers.DocsRouter} />
						<Route path="/forms" component={Routers.FormsRouter} />
						<Route path="/orders" component={Routers.OrdersRouter} />

						<Route path="/settings" component={Routers.SettingsRouter} />
						<Route path="/ustav" component={Routers.SettingsRouter} />
						<Route path="/vocations" component={Routers.SettingsRouter} />

						<Route path="/user" component={Routers.UserRouter} />

						<Route path="/calls" component={Routers.CallsRouter} />

						<Route path="/logs" component={Routers.LogsRouter} />

						<Route path="/mailbox" component={Routers.MailboxRouter} />

						<Route path="/reports" component={Routers.ReportsRouter} />

						<Route path="/task_manager" component={Routers.TaskManagerRouter} />

						<Route path="/sheets" component={Routers.SheetsRouter} />
						<Route path="/tables" component={Routers.TablesRouter} />

						<Route component={Page404} />
					</Switch>
				</div>
			</main>

			<ScrollTopButton />

		</div>
	);
};
const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		setCalendarSelectDateAction, sideBarToggle
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(ContentContainer);
