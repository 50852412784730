import React, {createRef, useEffect, useState} from 'react';
import sha256 from "sha256";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import {SESSION_USER, USER_LOGIN, userAuth} from "actions/UserActions";
import {DEBUG, PASSWORD_SALT, COOKIE_LOGIN, COOKIE_PASS, SESSION_LIFETIME} from "utils/config";
import Api, {apiAction} from "utils/Api";
import {Win, Button} from "components/_common";
import {NavLink} from "react-router-dom";
import {toastr} from "react-redux-toastr";
import Cookies from 'universal-cookie';
import {Storage} from "utils/Storage";
import {FormItem} from "components/_common";
import {is_empty} from "utils/func";



const LoginContainer = props => {

	const {user, actions} = props;

	const cookies = new Cookies();

	const [content, setContent] = useState(null);
	// console.log('LoginContainer');
	const onLogin = async (login, password, isRemember, passFromCookie = false) => {

		actions.showLoading();

		const passHash = passFromCookie ? password : sha256(password + PASSWORD_SALT);

		const res = await Api.post('users.php',  {
			action: 'login',
			login: login,
			password: passHash
		});
		console.log('LoginContainer onLogin');


		apiAction(res, (data) => {

			if (data && !is_empty(data)) {

				// Storage.session_set(SESSION_USER, data);
				Storage.set(SESSION_USER, data, SESSION_LIFETIME);

				if (!passFromCookie && isRemember) {
					cookies.set(COOKIE_LOGIN, login, {path: '/'});
					cookies.set(COOKIE_PASS, passHash, {path: '/'});
				}

				// console.dir(data);
				//не работает так как данные слишком большие
				// cookies.set(SESSION_USER, userData, {path: '/', maxAge : SESSION_LIFETIME});
				actions.userAuth(USER_LOGIN, data);
			}
		}, () => {
			actions.hideLoading();
		});
	};
	const footer = (
		<div className={"_tar"}>
			<NavLink to="/forgetpassword">Забыли пароль?</NavLink>
			{/*<NavLink to="/registration">Регистрация</NavLink>*/}
		</div>
	);

	const formRef = createRef();
	const onSubmit = (e) => {
		e.preventDefault();
		const form = formRef.current;

		if (form.login.value !== '' &&form.password.value !== '')
			onLogin(
				form.login.value.trim(),
				form.password.value.trim(),
				true//form.remember.checked
			);
		else
			toastr.warning('Внимание', 'Введите логин/пароль');
	};

	console.log('DEBUG', DEBUG);

	const logWin = (
		<div className="win-wide">
			<Win
				header={"Вход"}
				footer={footer}
				winClass={"login-win anim-win"}
			>
				<form
					ref={formRef}
					  onSubmit={(e) => {
						  onSubmit(e);
					  }}
				>
					<FormItem
						key={"login"}
						name={"login"}
						defVal={""}
						isRequire={true}
						ico={"user"}
						placeholder={"Логин"}
					/>
					<FormItem
						key={"password"}
						fieldType={"password"}
						name={"password"}
						defVal={""}
						isRequire={true}
						ico={"key"}
						placeholder={"Пароль"}
					/>

					{/*<div className="form-item">*/}
					{/*	<label className={"check"}>*/}
					{/*		<input*/}
					{/*			type={"checkbox"}*/}
					{/*			name={"remember"}*/}
					{/*			defaultValue={"1"}*/}
					{/*		/>*/}
					{/*		Запомнить меня*/}
					{/*	</label>*/}
					{/*</div>*/}
					<div className="form-item _tac">
						<Button
							type={"apply"}
							title={"Войти"}
							buttonType={"button"}
						/>
					</div>
				</form>
			</Win>
		</div>
	);

	useEffect(() => {
		let isSubscribe = true;

		// if (isSubscribe && !user) {
		// 	const cLogin = cookies.get(COOKIE_LOGIN);
		// 	const cPass = cookies.get(COOKIE_PASS);
		// 	// console.log(cLogin + cPass);
		// 	if (cLogin && cPass)
		// 		onLogin(cLogin, cPass, true, true).then(null);
		// 	else
		// 		setContent(logWin);
		// }


		return () => isSubscribe = false
	}, []);

	return logWin;
	// return content;

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	// userLogin : (login, password) => dispatch(userLogin(login, password)),
	actions: bindActionCreators({
		showLoading, hideLoading,
		userAuth
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
