import React, {useState, useEffect, useRef} from 'react';
import {useParams} from "react-router-dom";
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {rolesModerator, MENU_TITLE, MESSAGES} from "utils/config";
import {toastr} from "react-redux-toastr";
import {ContentHeader, BUTTON_TYPE, Button, Win, FormItem} from "components/_common";
import {TasksList} from "components/tasks";
// import {NavLink} from "react-router-dom";
import {
	get_noun,
	in_array,
	is_empty,
	isManager,
	isRole,
	scrollTo,
	user4api,
	sortArrayOfObjects,
	showToast, showConfirm
} from "utils/func";
import {setIsPrint, setCalendarSelectDateAction, setSearchAction, setTopbarActions} from "actions/GUIActions";
import date from "php-date";
import {saveSync} from "save-file";
import {sortTasks, canSendSms, canCreateTask} from "components/tasks/utils";
import * as strtotime from "strtotime";
import {UserInfo} from "components/tasks";
import {toast} from "react-toastify";

const API_URL = 'tasks.php';

export const deleteTask = async (id, props) => {
	// console.log('deleteTask ' + id);

	let apiActionProcess = false;

	showConfirm(
		'tasks-delete-task',
		'Вы точно хотите удалить заявку?',
		async () => {
			if (!apiActionProcess) {
				props.actions.showLoading();
				apiActionProcess = true;

				const res = await Api.post(API_URL, {
					action: 'deleteTask',
					user: user4api(props.user),
					id: id
				});

				apiAction(res, (data) => {
					props.history.push('/tasks');
				}, () => {
					apiActionProcess = false;
					props.actions.hideLoading();

				});
			}

		}
	);
	// const Confirm = () => (
	// 	<div className={"toast-confirm"}>
	//
	// 		<Button
	// 			type={BUTTON_TYPE.applyIco}
	// 			ico={"check"}
	// 			title={"Да"}
	// 			onClick={async () => {
	// 				if (!apiActionProcess) {
	// 					props.actions.showLoading();
	// 					apiActionProcess = true;
	//
	// 					const res = await Api.post(API_URL, {
	// 						action: 'deleteTask',
	// 						user: user4api(props.user),
	// 						id: id
	// 					});
	//
	// 					apiAction(res, (data) => {
	// 						props.history.push('/tasks');
	// 					}, () => {
	// 						apiActionProcess = false;
	// 						props.actions.hideLoading();
	// 						toast.dismiss('tasks-delete-task');
	// 					});
	// 				}
	// 			}}
	// 		/>
	// 		<Button
	// 			type={BUTTON_TYPE.closeIco}
	// 			title={"Нет"}
	// 			onClick={async () => {
	// 				toast.dismiss('tasks-delete-task');
	// 			}}
	// 		/>
	// 	</div>
	// );
	//
	// showToast('Вы точно хотите удалить заявку?', 'confirm', 0, {
	// 	position: 'top-center',
	// 	closeOnClick: false,
	// 	toastId: 'tasks-delete-task'
	// }, <Confirm/>);
	//
	// toastr.confirm(
	//     MESSAGES.confirmAction,
	//     {
	//         okText: 'Да',
	//         cancelText: 'Нет',
	//         onOk: async () => {
	//
	//             if (!apiActionProcess) {
	//                 props.actions.showLoading();
	//                 apiActionProcess = true;
	//
	//                 const res = await Api.post(API_URL, {
	//                     action: 'deleteTask',
	//                     user: user4api(props.user),
	//                     id: id
	//                 });
	//
	//                 apiAction(res, (data) => {
	//                     props.history.push('/tasks');
	//                 }, () => {
	//                     apiActionProcess = false;
	//                     props.actions.hideLoading();
	//                 });
	//             }
	//         },
	//
	//     }
	// );
};

const TasksListContainer = props => {

	const {user, GUI, actions, forTodo = null} = props;

	const {todo = ''} = useParams();
	const {paramDateStart = null} = useParams();

	const [onlyTodoList, setOnlyTodoList] = useState(
		(todo != undefined && todo !== '') ? todo.split(',') : []
	);
	const [tasksData, setTasksData] = useState(null);
	const [showTasks, setShowTasks] = useState([]);

	const filterSelectedOption = useRef('all');
	const filterShowOnlyUser = useRef(0);//показывать только "мои"

	const [popupWin, setPopupWin] = useState(null);

	const filterTasks = (tasks) => {
		// console.clear();
		tasks = tasks || tasksData.tasks;

		let selectedOption = filterSelectedOption.current;

		// console.log(selectedOption);
		// console.dir(tasks);
		// filterSelectedOption.current = selectedOption || 'all';

		// if (filterShowOnlyUser.current != 0) {
		//     let _tasks = [];
		//     tasks.map(task)
		// }

		// console.log(filterShowOnlyUser.current);

		// if (selectedOption == 'all') {
		//     setShowTasks(tasks);
		//     return;
		// }
		let _all = null;
		let filterTarget = '';
		let filterId = 0;

		if (selectedOption != 'all') {
			[_all, filterTarget, filterId] = /(\w*)-([\d]*)/.exec(selectedOption);
			filterId = parseInt(filterId);
		}

		// console.log(`selectedOption (all=${_all}, filterTarget=${filterTarget}, filterId=${filterId}), taskLength=${tasks.length}`);

		let filtered = [];

		tasks.map(task => {

			//только мои выкл  ИЛИ  включено и юзер совпадает
			if (filterShowOnlyUser.current == 0 || task.userId == filterShowOnlyUser.current) {
				switch (filterTarget) {
					case "division":
						if (task.divId === filterId)
							filtered.push(task);
						break;
					case "driver":

						if ((filterId === 0 && !task.driverId) || filterId === task.driverId)
							filtered.push(task);
						break;
					case "installer":
						if (task.installers !== undefined)
						console.log(task.id, task.installers, in_array(filterId, task.installers));

						if ((filterId === 0 && task.installers == undefined)
							||
							(Array.isArray(task.installers) && in_array(filterId, task.installers))
						)
							filtered.push(task);
						break;
					case "montaj":
						if (task.todo == 'instself')
							filtered.push(task);
						else if (task.deliveryInfo && task.deliveryInfo.installers_max
							&& parseInt(task.deliveryInfo.installers_max)) {
							filtered.push(task);
						}
						break;
					case "manager":
						if (task.userId === filterId)
							filtered.push(task);
						break;

					default:
						filtered.push(task);
						break;
				}
			}

			return null;
		});
		setShowTasks(filtered);
	};

	let apiActionProcess = false;

	const getTasks = async (isSubscribe = true, calDates = null, search = '') => {
		// console.clear();
		// console.log(calDates);
		// console.log(GUI.calendarDates);
		// console.log(onlyTodoList);
		if (!isSubscribe)
			return;

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'getTasks',
				user: user4api(user),
				dates: calDates ? calDates : GUI.calendarDates,
				search: search,
				onlyTodoList: onlyTodoList
			});

			apiAction(res, (data) => {
				console.dir(`getTasks tasks.length=${data.tasks.length}`);

				let managersInTasks = [];
				data.tasks.map(task => {
					if (task.userId && !in_array(task.userId, managersInTasks))
						managersInTasks.push(task.userId);
				});

				// let managers = [], managersInactive = [],
				//     installers = [],
				//     drivers = [];

				let managers = {},
					installers = {},
					drivers = {};
				Object.values(data.users).map(usr => {
					if (isManager(usr)) {
						usr.inTasks = in_array(usr.id, managersInTasks);
						managers[usr.id] = usr;
					}
					if (isRole(usr, ['driver'])) {
						drivers[usr.id] = usr;
					}
					if (isRole(usr, ['installer'])) {
						installers[usr.id] = usr;
					}
				});

				// console.dir(managers);
				// managers = sortArrayOfObjects(managers, 'name');
				// managersInactive = sortArrayOfObjects(managersInactive, 'name');
				// installers = sortArrayOfObjects(installers, 'name');
				// drivers = sortArrayOfObjects(drivers, 'name');

				// managers = managers.concat(managersInactive);

				// let managersObj = {}, driversObj = {}, installersObj = {};
				// managers.map(usr => {
				//     managersObj[usr.id] = usr;
				// });
				// installers.map(usr => {
				//     installersObj[usr.id] = usr;
				// });
				// drivers.map(usr => {
				//     driversObj[usr.id] = usr;
				// });

				data.managers = managers;
				data.installers = installers;
				data.drivers = drivers;

				setTasksData(data);
				filterTasks(data.tasks);
				// setShowTasks(data.tasks);

			}, () => {
				actions.hideLoading();
				apiActionProcess = false;
				scrollTo();
			});

			actions.hideLoading();
		}
	};

	const onCalendarSelectDate = async (dates) => {
		console.log(`^^^^^^^ onCalendarSelectDate`, dates);
		await getTasks(true, dates);

	};

	let newTaskLink = "/tasks/edit/0";
	if (todo !== '')
		newTaskLink += '---' + todo;

	let msTopActions = [];
	if (in_array(user.role, ['buyer', 'manager', ...rolesModerator]))
		msTopActions.push(
			<Button
				key={"ms-top-add-task"}
				href={newTaskLink}
				type={"save"}
				title={"Создать заявку"}
				tip={"Создать заявку"}
				ico={"plus-circle"}
				// cls={"ms-top-action-btn"}
			/>);

	useEffect(() => {
			let isSubscribe = true;

			let dates = null;
			if (paramDateStart) {
				const d = new Date(strtotime(paramDateStart) * 1000);
				dates = {
					start: d,
					end: d,
					year: date('Y', d),
					month: date('n', d),
					day: date('j', d)
				};
			}

			getTasks(isSubscribe, dates).then(null);

			actions.setCalendarSelectDateAction(onCalendarSelectDate);
			actions.setSearchAction(doSearch);

			actions.setTopbarActions(msTopActions);

			return () => {
				isSubscribe = false;
				//отключаем функцию обработчик на календаре
				actions.setCalendarSelectDateAction(null);
				actions.setSearchAction(null);
				actions.setTopbarActions(null);
			}
		},
		[]
		// ['getTasks', 'onlyTodoList']
	);

	const printAction = () => {
		// actions.setIsPrint(true);
		window.print();
	};

	const onSave = async (task, whatChange = 'execs') => {
		// console.clear();
		console.log(`onSave TASK in list whatChange=${whatChange}`);
		// console.dir(task);

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'setTaskExecutors',
				user: user,
				task: task,
				whatChange: whatChange
			});

			apiAction(res, (data) => {

				setTimeout(() => {
					getTasks(true);
				}, 500);
			}, () => apiActionProcess = false);

			actions.hideLoading();
		}
	};
	const isFakePhone = tel => {
		for (let i = 0; i <= 9; i++) {
			if (tel.indexOf(`${i}${i}${i}-${i}${i}-${i}${i}`) !== -1)
				return true;
		}

		return false;
	}

	const onSendSms = async taskId => {
		// console.clear();

		if (tasksData && tasksData.tasks && tasksData.tasks.length) {
			let phones = [],
				tasksIds = [];

			tasksData.tasks.map(task => {
				// console.log(task.id, task.deliveryInfo, canSendSms(task));
				// console.log(canSendSms(task))

				if (task.phoneMobile && task.phoneMobile != ''
					&& !isFakePhone(task.phoneMobile)
					&& canSendSms(task)
				) {
					if (taskId === 'all') {
						phones.push(task.phoneMobile);
						tasksIds.push(task.id);
					}
					else if (taskId === task.id) {
						phones.push(task.phoneMobile);
						tasksIds.push(taskId);
					}
				}
			});

			// console.log(phones);
			// console.log(tasksIds, tasksIds.indexOf('36733'));
			// console.log(phones.indexOf('(926) 393-09-25'));
			// return;

			if (phones.length) {
				toastr.confirm(
					(
						<div className="">
							{/*Отправить SMS на номер{phones.length > 1 ? 'а' : ''}:*/}
							{/*<br/>*/}
							{/*{phones.join(', ')}*/}
							Отправить SMS на {phones.length} {get_noun(phones.length, 'номер', 'номера', 'номеров')}
							{
								user.settings.g_sms_test == 1 ? (
										<h2 className={""}>
											<br/>
											ВНИМАНИЕ!!! Будет произведена ТЕСТОВАЯ отправка. Для ее отключения перейдите в
											настройки
										</h2>
									)
									: null
							}
						</div>
					),
					{
						okText: 'Да',
						cancelText: 'Нет',
						onOk: async () => {
							if (!apiActionProcess) {
								actions.showLoading();
								apiActionProcess = true;

								const res = await Api.post(API_URL, {
									action: 'sendSms',
									user: user,
									tasksIds: tasksIds
								});

								apiAction(res, (data) => {
									//в списке сообщений менять кнопку и текст если отослано
									let tmp = {...tasksData};
									let isSmsSends = false;
									if (tmp.tasks.length) {
										for (const tid in data) {
											tmp.tasks.map((t, key) => {
												if (parseInt(t.id) === parseInt(tid)) {
													isSmsSends = true;
													tmp.tasks[key].smsUserId = user.id;
													tmp.tasks[key].smsSendDate = data[tid];
												}
											});
										}
									}
									if (isSmsSends)
										setTasksData(tmp);
								}, () => {
									apiActionProcess = false;
									actions.hideLoading();
								});

							}
						}
					}
				);
			}
			else
				toastr.error('Нет номеров для отправки');
		}
		else
			toastr.error('Ошибка', 'Нет заявок', {timeOut: 0});

	};

	const doSearch = str => {
		getTasks(true, null, str).then(null);
	};

	const download_excel_filter = async () => {
		// console.clear();
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'download_excel',
				user: user,
				what: 'filtered',
				dates: GUI.calendarDates,
				tasks: sortTasks(showTasks, user, tasksData.divisions, tasksData.users),
				showFor: filterSelectedOption.current
			});

			apiAction(res, (data) => {
				if (data.fileData && data.fileName) {
					saveSync(
						data.fileData,
						data.fileName
					);
				}
				else
					toastr.error('Ошибка получения файла');
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
	};
	const download_excel_otchet = async () => {

		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'download_excel_otchet',
				user: user,
				dates: GUI.calendarDates,
				tasks: showTasks,
				showFor: filterSelectedOption.current
			});

			apiAction(res, (data) => {
				if (data.fileData && data.fileName) {
					saveSync(
						data.fileData,
						data.fileName
					);
				}
				else
					toastr.error('Ошибка получения файла');
			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}
		;
	};

	const showPopupWin = (title = '', content) => {
		setPopupWin(
			<div className="popup-win">
				<Win
					header={title}
					onClose={e => setPopupWin(null)}
					winClass={"wide"}
					// footer={
					// 	(
					// 		<div className={"win-ftr-btns _tac"}>
					// 			<Button type="apply" onClick={e => {
					// 				// setField(formId, field, popUpWinFieldRef.current.value).then(null);
					// 			}}/>
					// 		</div>
					// 	)
					// }
					// winClass={"half"}
				>
					{content}
				</Win>
			</div>
		);
	};
	const showPopup = async (args = {}) => {

		if (args.action) {
			if (!apiActionProcess) {
				actions.showLoading();
				apiActionProcess = true;

				const res = await Api.post(API_URL, {
					...args,
					user: user
				});

				apiAction(res, (data) => {

					if (args.action === 'showUserInfo') {
						showPopupWin(
							data.role == 'driver' ? 'Водитель' : 'Монтажник',
							<UserInfo
								{...data}
							/>
						);
					}

				}, () => {
					apiActionProcess = false;
					actions.hideLoading();
				});
			}
			;
		}
		else
			showPopupWin('Ошибка', 'Не задано действия при открытии окна');
	};
	const setField = async (id, field, value, setField = true) => {
		// console.clear();
		console.log('setField', field, value);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post(API_URL, {
				action: 'setField',
				user: user,
				id: id,
				field: field,
				value: value
			});

			apiAction(res, (data) => {
				// if (setField) {
				// 	let _orders = [...showOrders];
				//
				// 	_orders.map((order, key) => {
				// 		if (order.id === id) {
				// 			_orders[key][field] = data.value;
				//
				// 			if (data.status != -1)
				// 				_orders[key]['status'] = data.status;
				// 		}
				// 		return null;
				// 	});
				//
				// 	setShowOrders(_orders);
				// }

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});

			apiActionProcess = false;
		}
	};

	/////////////////////// ВЫВОД

	const dateStart = date('d.m.Y', GUI.calendarDates.start);
	const dateEnd = date('d.m.Y', GUI.calendarDates.end);

	let printTasksSelect = [];
	let printTasksSelectDates = 'за ' + dateStart;
	if (dateStart !== dateEnd)
		printTasksSelectDates = 'с ' + dateStart + ' по ' + dateEnd;
	printTasksSelect.push(
		<option key={"all"} value={"all"}>Все заявки {printTasksSelectDates}</option>
	);

	if (tasksData) {
		// console.dir(tasksData);

		printTasksSelect.push(
			<optgroup label="Для офиса:" key={"pts-divs"}>
				{
					Object.keys(tasksData.divisions).map(id => {
						return <option
							key={"pts-div-" + id}
							value={"division-" + id}
						>
							{tasksData.divisions[id].name}
						</option>;
					})
				}
			</optgroup>
		);

		if (!is_empty(tasksData.managers)) {

			let managersHasTask = [], managersOthers = [];
			Object.values(tasksData.managers).map(usr => {
				if (usr.inTasks)
					managersHasTask.push(usr);
				else
					managersOthers.push(usr);

				return null;
			});

			managersHasTask = sortArrayOfObjects(managersHasTask, 'name');
			managersOthers = sortArrayOfObjects(managersOthers, 'name');

			printTasksSelect.push(
				<optgroup label="Для менеджера:" key={"pts-man"}>
					{
						managersHasTask.concat(managersOthers).map(usr => {
							return <option
								key={"pts-man-" + usr.id}
								value={"manager-" + usr.id}
								style={{color: !usr.inTasks ? '#ccc' : null}}
							>
								{usr.name}
							</option>;
						})
					}
				</optgroup>
			);
		}

		printTasksSelect.push(
			<optgroup label="Для водителя:" key={"pts-drivers"}>
				<option
					key={"pts-drivers-" + 0}
					value={"driver-" + 0}
				>
					...не назначен
				</option>
				{
					sortArrayOfObjects(Object.values(tasksData.drivers), 'name').map(usr => {
						return <option
							key={"pts-drivers-" + usr.id}
							value={"driver-" + usr.id}
						>
							{usr.name}
						</option>;
					})
				}
			</optgroup>
		);
		printTasksSelect.push(
			<optgroup label="Для монтажника:" key={"pts-inst"}>
				<option
					key={"pts-inst-" + 0}
					value={"installer-" + 0}
				>
					...не назначен
				</option>
				{
					sortArrayOfObjects(Object.values(tasksData.installers), 'name').map(usr => {
						return <option
							key={"pts-inst-" + usr.id}
							value={"installer-" + usr.id}
						>
							{usr.name}
						</option>;
					})
				}
			</optgroup>
		);
		printTasksSelect.push(
			<option
				key={"montaj-0"}
				value={"montaj-0"}
			>
				Монтаж
			</option>
		);
	}

	let banCreateTask = null;
	if (canCreateTask(user.role))
		banCreateTask = <div className={"mobile-hide"}>
			<Button
				href={newTaskLink}
				type={"save"}
				title={"Создать заявку"}
				ico={"plus-circle"}
			/>
		</div>;
	// const panelLeft = (
	// 	<div className="-l">
	// 		<CalendarContainer calendarOnly={true}/>
	// 	</div>
	// );
	const panelRight = (
		<div className="-r">
			<div className="flx mt20 tasks-panel-btns-wrp">
				<div className="flx tasks-panel-btns">
					{
						(isManager(user))
							? <FormItem
								fieldType={"bool"}
								label={"Мои заявки"}
								name={"showMyTasks"}
								cls={"check-my-tasks"}
								noBgr={true}
								changeAction={e => {
									filterShowOnlyUser.current = e.target.checked ? user.id : 0;
									filterTasks();
								}}
							/> : null
					}

					{
						(tasksData
							&& isRole(user, ['user', 'logist', ...rolesModerator])
							&& tasksData.tasks
							&& tasksData.tasks.length) ? (
							<>


								<div className="-dates-select">
									<select
										onChange={e => {
											filterSelectedOption.current = e.target.value;
											filterTasks();
										}}
									>
										{printTasksSelect}
									</select>
								</div>
								<Button
									title={"Печать"}
									ico={"print"}
									onClick={printAction}
									type={""}
									cls={"-lgrey"}
								/>
								<Button
									title={"Excel"}
									ico={"file-excel-o"}
									onClick={download_excel_filter}
									type={""}
									cls={"-lgrey"}
								/>
								<Button
									title={"Общий отчет"}
									ico={"file-excel-o"}
									onClick={download_excel_otchet}
									type={""}
									cls={"-lgrey"}
								/>
								<Button
									title={"Отправить SMS всем"}
									ico={"mobile"}
									onClick={e => onSendSms('all')}
									type={"save"}
								/>
							</>
						) : null
					}
				</div>
			</div>

		</div>
	);

	const panel = (
		<div className="panel-top panel-top-tasks mt20m no-print">
			{/*{panelLeft}*/}
			{panelRight}
		</div>
	);

	return (
		<>
			<ContentHeader
				// title={MENU_TITLE.tasks.many}
				title={(
					<div className={"flx -alc"}>
						{MENU_TITLE.tasks.many}
						&nbsp;&nbsp;&nbsp;&nbsp;
						{banCreateTask}
					</div>
				)}
				// parent={{url: '/tasks', name: 'Заявки'}}
				showDates={true}
				datesAction={getTasks}
			/>
			{
				in_array(user.role, ['user', 'logist', 'buyer', 'manager', ...rolesModerator])
					? panel
					: null
			}
			{
				tasksData ?
					<TasksList
						{...tasksData}
						showTasks={showTasks}
						user={user}
						GUI={GUI}
						onSave={onSave}
						onSendSms={onSendSms}
						showPopup={showPopup}
						setField={setField}
					/>
					: (
						<div className={"mt20"}>
							Заявок не найдено
						</div>
					)
			}
			{popupWin}
		</>
	)
};

const mapStateToProps = store => ({
	user: store.user,
	GUI: store.GUI
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading, setIsPrint, setCalendarSelectDateAction, setSearchAction, setTopbarActions
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(TasksListContainer);
