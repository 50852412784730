import axios from "axios";
import {API_URL} from "utils/config";
import {toastr} from "react-redux-toastr";
import { toast } from 'react-toastify';
import {showToast} from "./func";


export default axios.create({
    baseURL: API_URL,
    responseType: "json",
    timeout: 30000,
    headers: {
        "Content-Type": "application/x-www-form-urlencoded"
        // "Content-Type": "multipart/form-data"
        // "Access-Control-Allow-Origin": "*"
    },
    transformResponse: null
});

export const apiAction = (res, callback, final = null, showToastMessage = true) => {

    if (res && res.data) {
        console.log('server response', res.data.time);
        if (res.data.error != '')
            showToast(res.data.error, 'error', 0)
        else if (typeof callback == 'function')
            callback(res.data.response);

        if (showToastMessage) {
            showToast(res.data.info, 'info');
            showToast(res.data.warning, 'warning');
        }
    }
    else {
        showToast('Данные не получены, см. код ответа сервера', 'error');
    }


    if (typeof final == 'function')
        final(res);
};
