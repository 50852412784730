import React, {useEffect, useState, createRef} from 'react';
import {bindActionCreators} from "redux";
import {hideLoading, showLoading} from "react-redux-loading-bar";
import {connect} from "react-redux";
import Api, {apiAction} from "utils/Api";
import {toastr} from "react-redux-toastr";
import {useParams} from "react-router-dom";
import {ContentHeader, Win, Button} from "components/_common";
import {in_array, toArray} from "utils/func";
import {rolesModerator, rolesAdmin} from "utils/config";
import {UserEdit} from "components/user";


const UserEditContainer = props => {

	const {user, actions, edit = true, editSelf = false} = props;

	let { id = -1, roleName = 'user' } = useParams();
	const userId = (editSelf && user) ? parseInt(user.id) : parseInt(id);

	// let returnUrl = '/user';
	// console.dir(props.history);

	const formRef = createRef();
	const avatarFileRef = createRef();

	const [userData, setUserData] = useState(null);
	const [divisions, setDivisions] = useState(null);

	let apiActionProcess = false;
	const onLoad = async (isSubscribe) => {
		if (!isSubscribe)
			return;
		// console.log('getUser: userId=' + userId + '/ id=' + id + ' / roleName=' + roleName);
		if (!apiActionProcess) {
			actions.showLoading();
			apiActionProcess = true;

			const res = await Api.post('users.php',  {
				action: 'getUsers',
				user: user,
				id: userId
			});

			apiAction(res, (data) => {
				// console.log(data);
				if (data.users) {
					setUserData(
						userId ? data.users : null
					);
					// returnUrl += '/role/' + data.users.role
				}
				if (data.divisions)
					setDivisions(data.divisions);

			}, () => {
				apiActionProcess = false;
				actions.hideLoading();
			});
		}

	};
	useEffect(() => {
		let isSubscribe = true;

		onLoad(isSubscribe).then(r => {});
		return () => {
			isSubscribe = false;
			setUserData(null);
			setDivisions(null);
		}
	}, [userId]);


	const saveUser = async (isReturn = false) => {
		// console.clear();
		// console.dir(userData);
		const form = formRef.current;
		const avatarFile = avatarFileRef.current;

		let err = [];

		if (form.login.value == '')
			err.push('Логин не задан');

		if (!userId && form.password.value == '')
			err.push('Пароль не задан');

		if (form.name.value == '')
			err.push('Имя не задано');

		// if (form.email.value == '')
		// 	err.push('Email не задан');

		if (err.length) {
			{err.map((txt, i) => {
				toastr.error('Ошибка заполнения', txt);
			})}
		}
		else {

			actions.showLoading();

			const formData = new FormData();

			formData.append('action', 'saveUser');
			formData.append('user', JSON.stringify(user));
			const data = {
				id: userData ? userData.id : 0,
				login: form.login.value,
				password: form.password.value,
				role: form.role ? form.role.value : userData.role,
				name: form.name.value,
				email: form.email.value,
				phone: form.phone.value,
				info: form.info.value,
				passport: form.passport != undefined ? form.passport.value : '',
				car: form.car != undefined ? form.car.value : '',
				num: form.num != undefined ? form.num.value : '',
				divId: form.divId.value,
				dopDivId: userData.dopDivId ? toArray(userData.dopDivId) : [],
			};

			formData.append('data', JSON.stringify(data));
			formData.append('avatar', avatarFile.files[0]);
			console.clear();
			console.dir(data);
			// return;


			const res = await Api.post('users.php', formData);

			apiAction(res, (resp) => {

				if (isReturn) {
					// if (data.role)
					// 	returnUrl += '/role/' + data.role;
					//
					// props.history.push(returnUrl);
					props.history.goBack();
				}


			});
			actions.hideLoading();
		}

	};

	let contentHeaderParent = [];
	if (in_array(user.role, rolesModerator))
		contentHeaderParent = [
			{url: '/user', name: "Пользователи"},
		];

	let onClose = null;
	if (!editSelf)
		onClose = () => props.history.push('/user');

	let content = null;
	if (divisions)
		content = <UserEdit
			userId={userId}
			user={user}
			formRef={formRef}
			userData={userData}
			setUserData={setUserData}
			edit={edit}
			divisions={divisions}
			rolesList={user.userRoles}
			avatarFileRef={avatarFileRef}
			roleNameDefault={roleName}
		/>;

	let title;
	if (edit)
		title = id ? 'Редактирование' + (editSelf ? ' профиля' : '') : 'Создание пользователя';
	else
		title = 'Информация о пользователе';

	let editButton = null;
	if (in_array(user.role, rolesAdmin) || (userData && userData.divId == user.divId && in_array(user.role, rolesModerator)))
		editButton = <Button
			type="save"
			title={"Редактировать"}
			onClick={() => props.history.push('/user/edit/' + userData.id)}
		/>;

	return (
		<>
			<ContentHeader
				parent={contentHeaderParent}
				title={title}
			/>
			<Win
				onClose={() => !editSelf ? props.history.goBack() : null}
				header={"ID: " + (userData ? userData.id : '')}
				footer={
					edit ?
						(
							<div className={"win-ftr-btns"}>
								<Button type="apply" onClick={() => saveUser(0)}/>
								{!editSelf ? (
									<>
										<Button type="save" onClick={() => saveUser(1)}/>
										<Button type="close" onClick={() => {
											props.history.goBack();
											// props.history.push(returnUrl)
										}} />
									</>
								) : null
								}
							</div>
						)
						: editButton
				}
			>
				{content}
			</Win>
		</>
	)

};

const mapStateToProps = store => ({
	user: store.user
});

const mapDispatchToProps = dispatch => ({
	actions: bindActionCreators({
		showLoading, hideLoading
	}, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(UserEditContainer);
