import React, {useState, useEffect, createRef} from 'react';
import Api, {apiAction} from "utils/Api";
import date from "php-date";
import {MONTHS, DAYS_SHORT, rolesAdmin} from "utils/config";
import {Win, Button, FormItem, SmartSelect} from "components/_common";
import * as strtotime from "strtotime";
import {toastr} from "react-redux-toastr";
import {array_unset, isAdmin as checkIsAdmin, in_array} from "utils/func";

const CalendarType = {
    // EVENT: 'event',//ХЗ зачем пока не используется
    HOLIDAYS: 'holiday',
    VOCATIONS: 'vocations'
}

const VOCATIONS_MAX_DAYS = 4 * 7;

const Calendar = props => {

    const {user, actions, type = CalendarType.HOLIDAYS} = props;


    const isAdmin = checkIsAdmin(user);

    // console.log(type);

    const [calendarDatesInit, setCalendarDatesInit] = useState({});
    const [calendarDates, setCalendarDates] = useState(null);
    // const [myVocations, setMyVocations] = useState({});

    //только для менеджеров, до 06/09/24
    const [managers, setManagers] = useState(null);
    const [managerId, setManagerId] = useState(user.role == 'manager' ? user.id : 0);

    const [users, setUsers] = useState(null);

    let defSelectedUsersId = [];
    if (!isAdmin)
        defSelectedUsersId.push(user.id);
    const [selectedUsersId, setSelectedUsersId] = useState(defSelectedUsersId);//72

    const [userRoles, setUserRoles] = useState(null);//Object key - role code, value - role name
    // const [divisions, setDivisions] = useState(null);


    const [isDeleteDates, setDeleteDates] = useState(false);

    const CURRENT_YEAR = (new Date()).getFullYear();
    const CURRENT_MONTH = (new Date()).getMonth();


    const [popupWin, setPopupWin] = useState(null);




    let apiActionProcess = false;
    const editNameRef = createRef();
    const getData = async (isSubscribe = true) => {

        if (!isSubscribe)
            return;


        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            const res = await Api.post('settings.php', {
                action: 'getCalendar',
                user: user,
                type: type || CalendarType.EVENT
            });

            apiAction(res, (data) => {
                console.clear();
                console.log(data);
                let cd = Array.isArray(data.calendar) ? {} : data.calendar;
                // console.dir(cd);
                setCalendarDates(cd);
                setCalendarDatesInit(cd);

                if (type == CalendarType.VOCATIONS) {
                    let myDates = {};
                    for (let d in cd) {
                        if (parseInt(cd[d].userId) === user.id) {
                            myDates[d] = cd[d];
                        }
                    }
                    // setMyVocations(myDates);
                }
                if (data.managers)
                    setManagers(data.managers);
                if (data.users)
                    setUsers(data.users);

                if (data.roles)
                    setUserRoles(data.roles);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            });
        }
    };

    useEffect(() => {
            let isSubscribe = true;
            getData(isSubscribe).then(null);

            return () => {
                isSubscribe = false;
                setCalendarDates(null);
            }
        },
        [type]
    );
    const saveDate = async (calDate, isDelete) => {
        console.log('saveDate ' + calDate + ' / ' + isDelete);

        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;

            let newCalendarDates = {...calendarDates};
            // let newCalendarDates = {};
            //с 12/09/24 calendarDates - каждая дата - это массив, так приходит с сервера
            // for (let _date in calendarDates) {
            //     newCalendarDates[ _date ] = calendarDates[ _date ][0];
            // }


            if (newCalendarDates[calDate] === undefined)
                newCalendarDates[calDate] = [{}];

            if (isDelete) {
                newCalendarDates[calDate][0].name = 'delete';
            }
            else if (editNameRef && editNameRef.current)
                newCalendarDates[calDate][0].name = editNameRef.current.value.trim();

            // for (let _date in newCalendarDates) {
            //     newCalendarDates[_date].map((dayObj, i) => {
            //         console.dir(dayObj);
            //         if (isDelete) {
            //             newCalendarDates[_date][i].name = 'delete';
            //         }
            //         else if (editNameRef && editNameRef.current)
            //             newCalendarDates[_date][i].name = editNameRef.current.value.trim();
            //     })
            // }

            // if (isDelete) {
            //     // delete newCalendarDates[calDate];
            //     newCalendarDates[calDate].name = 'delete';
            // }
            // else if (editNameRef && editNameRef.current)
            //     newCalendarDates[calDate].name = editNameRef.current.value.trim();



            // console.dir(newCalendarDates);
            // return;
            const res = await Api.post('settings.php', {
                action: 'setCalendar',
                user: user,
                calendarDates: newCalendarDates,
                type: type
            });


            apiAction(res, (data) => {
                // console.log(data);

                setCalendarDates(newCalendarDates);
                setPopupWin(null);

                let userSettingsCalendarDates = [];
                for (let d in newCalendarDates) {
                    userSettingsCalendarDates.push(
                        date('Y-n-j', new Date(strtotime(d) * 1000))
                    );
                }
                actions.userSetSettings('holidays', userSettingsCalendarDates);
                // console.log(userSettingsCalendarDates);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
                setPopupWin(null);
            });
        }
    };

    const saveDates = async (dates, callback = null) => {
        console.log('saveDates');
        if (!apiActionProcess) {
            actions.showLoading();
            apiActionProcess = true;


            const res = await Api.post('settings.php', {
                action: 'setCalendar',
                user: user,
                calendarDates: dates,
                type: type
            });

            apiAction(res, (data) => {

                if (typeof callback === 'function')
                    callback(data);

            }, () => {
                apiActionProcess = false;
                actions.hideLoading();
            }, false);
        }
    }

    const editor = (selDate, data, isSelected) => {
        return <div className="popup-win">
            <Win
                header={date('d.m.Y', new Date(strtotime(selDate) * 1000))}
                onClose={e => setPopupWin(null)}
                footer={
                    (
                        <div className={"win-ftr-btns _tac"}>
                            <Button type="applyIco"
                                    onClick={e => saveDate(selDate, false)}
                            />
                            {
                                isSelected ? <Button type="delete"
                                                     onClick={e => saveDate(selDate, true)}
                                /> : null
                            }

                        </div>
                    )
                }
                winClass={"half"}
            >
                <FormItem
                    name={"txt"}
                    label={"Описание"}
                    fieldType={"text"}
                    defVal={data ? data.name : ''}
                    reff={editNameRef}
                />
            </Win>
        </div>;
    };

    const onSelectDate = async (e, date, isSelected) => {
        console.log('onSelect date ' + date + '/' + isSelected);

        switch (type) {
            case CalendarType.HOLIDAYS:

                let dayData = (calendarDates[date] && calendarDates[date].length) ? calendarDates[date][0] : null;


                setPopupWin(
                    editor(
                        date,
                        dayData,
                        isSelected
                    )
                );
                break;
            case CalendarType.VOCATIONS:
                // if (!isDeleteDates && managerId == 0) {
                //     toastr.error('Ошибка', `Выберите менеджера`);
                //     return;
                // }
                if (!isDeleteDates && selectedUsersId.length == 0) {
                    toastr.error('Ошибка', `Выберите пользователей`);
                    return;
                }
                await vocationSelectDates(date, isSelected);
                break;
            default:
                toastr.error('Ошибка', `Нет обработчика выбора даты для Календаря типа "${type}"`);
                break;
        }


    };


    const calendarDatesItem = (userId, cdate, ctype, name = '', id = 0, created = '', userName = '') => {
        return {
            id: id,
            userId: userId,
            userName: userName,
            ctype: ctype,
            cdate: cdate,
            name: name,
            created: created
        };
    }

    //const [selectedPeriod, setSelectedPeriod] = useState([]);
    // const checkIntersectVocations = (date) => {
    // 	if (calendarDatesInit[date] && calendarDatesInit[date].userId !== user.id) {
    //
    //
    // 		toastr.warning(
    // 			'!!!',
    // 			//'Это число зарезервировано за: ' + calendarDates[date].name
    // 			'Выбранные вами даты зарезервированы другим человеком'
    // 		);
    // 		setSelectedPeriod([]);
    // 		return true;
    // 	}
    //
    // 	return false;
    // };
    const vocationSelectDates = async (date2set, isSelected) => {
        console.clear();
        console.log(`vocationSelectDates: ${date2set}, isSelected=${isSelected}, isDeleteDates=${isDeleteDates}`);

        /*
        if (isDeleteDates) {
            let cd2save = {...calendarDates};
            let cd2show = {...cd2save};
            if (cd2save[date] != undefined) {
                if (isAdmin || cd2save[date].userId == user.id) {
                    cd2save[date].name = 'delete';
                    delete cd2show[date];
                    await saveDates(cd2save, (data) => {
                        setCalendarDates(cd2show);
                    });
                }
                else if (managers && managers[cd2save[date].userId])
                    toastr.warning('Внимание!', `Вы не можете удалить дату пользователя "${managers[cd2save[date].userId].name}"`);
            }
            return;
        }

        if (managerId && !isSelected) {
            let cdAll = {...calendarDates};
            cdAll[date] = calendarDatesItem(managerId, date, CalendarType.VOCATIONS);

            await saveDates(cdAll, (data) => {
                setCalendarDates(cdAll);
            });

        }
         */

        if (selectedUsersId.length) {
            let cdAll = {...calendarDates};
            console.log(selectedUsersId);
            // console.dir(cdAll);

            if (cdAll[ date2set ] == undefined)
                cdAll[ date2set ] = [];

            let usersIdExists = [];
            cdAll[date2set].map((usr, i) => {
                usersIdExists.push(usr.userId);
            });
            console.log(usersIdExists);

            selectedUsersId.map(_userId => {
                console.log(usersIdExists, _userId, in_array(_userId, usersIdExists));
                let _usr = users[ _userId ];
                //удаляем вбанных пользователей
                if (isDeleteDates) {
                    cdAll[date2set].map((dusr, i) => {
                        if (dusr.userId == _userId)
                            cdAll[date2set] = array_unset(cdAll[date2set], i);
                    });
                }
                else {
                    if (!in_array(_userId, usersIdExists)) {
                        cdAll[date2set].push(calendarDatesItem(_usr.id, date2set, CalendarType.VOCATIONS, '', 0, '', _usr.name));
                    }
                }
            });

            // console.dir(cdAll);
            // setCalendarDates(cdAll);
            await saveDates(cdAll, () => {
                setCalendarDates(cdAll);
            });
        }
    }


    if (!calendarDates)
        return null;


    let months = [];
    let totalVocationDays = {};

    Object.values(calendarDates).map(dayList => {

        dayList.map(day => {
            if (totalVocationDays[day.userId] == undefined)
                totalVocationDays[day.userId] = {
                    count: 0,
                    exceeded: false
                }

            totalVocationDays[day.userId].count++;

            if (totalVocationDays[day.userId].count > VOCATIONS_MAX_DAYS)
                totalVocationDays[day.userId].exceeded = true;
        });

    });
    // console.dir(totalVocationDays);
    // managers[managerId] ? name2short(managers[managerId].name) : ''
    // console.clear();
    // console.dir(calendarDates);
    for (let i = 0; i < 12; i++) {
        months.push(
            <MonthComponent
                key={'cat-m-' + i}
                year={CURRENT_YEAR}
                month={i}
                onSelectDate={onSelectDate}
                calendarDates={calendarDates}
                type={type}
                // managerId={managerId}
                selectedUsersId={selectedUsersId}
                // users={managers}
                totalVocationDays={totalVocationDays}
            />
        );
    }
    let nextYear = [];

    if (CURRENT_MONTH >= 8) {
        nextYear.push(<h2 key={'year-next'}>{(CURRENT_YEAR + 1)} г.</h2>);
        let nextYearMonths = [];
        for (let i = 0; i < 12; i++) {
            nextYearMonths.push(
                <MonthComponent
                    key={'cat-m-' + (CURRENT_YEAR + 1) + i}
                    year={(CURRENT_YEAR + 1)}
                    month={i}
                    onSelectDate={onSelectDate}
                    calendarDates={calendarDates}
                    type={type}
                    // managerId={managerId}
                    selectedUsersId={selectedUsersId}

                    // selectedPeriod={type == CalendarType.VOCATIONS ? selectedPeriod : null}
                />
            );
        }
        nextYear.push(
            <div className="calendar row" key={'cal-next'}>
                {nextYearMonths}
            </div>
        );

    }

    // let managersSelect = null;
    // if (isAdmin && type === CalendarType.VOCATIONS && managers) {
    //     let options = {0: {value: 'Выберите менеджера'}};
    //     Object.values(managers).map((man) => {
    //         options[man.id] = {value: man.name};
    //     });
    //     managersSelect = <FormItem
    //         label={"Для менеджера"}
    //         name={"managerId"}
    //         fieldType={"select"}
    //         options={options}
    //         changeAction={(e, val) => setManagerId(parseInt(val))}
    //     />;
    // }
    //выбор всех пользователей
    let usersSelect = null;
    if (isAdmin && type === CalendarType.VOCATIONS && users && userRoles) {
        // let managersByDiv = {};

        const userByRolesAndDiv = {};
        for (let role in userRoles) {
            userByRolesAndDiv[role] = {};
        }

        let divisionsNames = {};
        let usersArray = Object.values(users);
        usersArray.map(usr => {

            let {role, divId} = usr;
            if (!userByRolesAndDiv[role].hasOwnProperty(divId)) {
                userByRolesAndDiv[role][divId] = [];
                divisionsNames[usr.divId] = usr.divName ? usr.divName : 'нет офиса';
            }

            userByRolesAndDiv[role][divId].push(usr);
        });


        let ssOptions = [];


        for (let role in userByRolesAndDiv) {
            for (let divId in userByRolesAndDiv[role]) {
                if (userByRolesAndDiv[role][divId].length) {
                    ssOptions.push({
                        name: `${userRoles[role]} (${divisionsNames[divId]})`,
                        value: `${role}-${divId}`,
                        selected: false,
                        isGroup: true
                    });

                    userByRolesAndDiv[role][divId].map(usr => {
                        ssOptions.push({
                            name: usr.name,
                            value: usr.id,
                            selected: in_array(usr.id, selectedUsersId),
                        });
                    });
                }
            }
        }

        usersSelect = <SmartSelect
            label={'Выберите пользователей, для которых устанавливаете дни отпуска'}
            options={ssOptions}
            multi={true}
            withSearch={true}
            onSelect={(res) => {
                setSelectedUsersId([...res.keys()]);
            }}
        />
    }


    return (
        <>
            <h2>{CURRENT_YEAR} г.</h2>

            {
                type === CalendarType.VOCATIONS
                    ? <div className="row mt20">
                        <div className="col-2">
                            {/*{managersSelect}*/}
                            {usersSelect}
                        </div>
                        <div className="col-1 mt30">
                            <FormItem
                                name={"isDelete"}
                                fieldType={"bool"}
                                label={"Удалить дни"}
                                changeAction={e => setDeleteDates(e.target.checked)}
                            />
                        </div>

                    </div>
                    : null
            }


            <div className="calendar row">
                {months}
            </div>
            {nextYear}
            {popupWin}
        </>
    )

};

export default Calendar;

const MonthComponent = props => {
    const {
        year,
        month,
        onSelectDate,
        calendarDates,
        type,
        managerId = 0,
        selectedUsersId = [],
        // users = null,
        totalVocationDays = null,
        // selectedPeriod
    } = props;


    const totalDays = new Date(year, month + 1, 0).getDate();
    // console.log(month + '/' + MONTHS[month] + ' / totalDays: ' + totalDays);
    // console.log(new Date(year, month, 1));
    // console.log('--------');
    const firstDayNum = date("N", new Date(year, month, 1));
    const totalWeeks = Math.ceil(totalDays / 7);
    // console.log(month + totalDays + ' / ' + firstDayNum + ' / ' + totalWeeks);
    // console.log(calendarDates);
    let weeks = [];

    let dayIterator = 1;
    for (let i = 1; i <= totalWeeks; i++) {
        let row = [];

        for (let j = 1; j <= 7; j++) {

            let tdClass = [];
            if (j == 6)
                tdClass.push('st');
            if (j == 7)
                tdClass.push('su');

            let day = '';

            if (i === 1 && j >= firstDayNum)
                day = dayIterator++;
            else if (i > 1 && dayIterator <= totalDays)
                day = dayIterator++;

            const monthNum = month + 1;
            const dayDate = year + '-'
                + (monthNum < 10 ? '0' : '') + monthNum
                + '-'
                + (day < 10 ? '0' : '') + day;
            // console.log(dayDate);


            // let dayUserId = calendarDates[dayDate] ? parseInt(calendarDates[dayDate].userId) : 0;
            let selected = false,
                title = '',
                userName = '',
                snoska = '',
                tdContent = [];

            if (calendarDates[dayDate] && calendarDates[dayDate].length) {
                selected = true;

                // title = calendarDates[dayDate].name;
                //
                // if (type == CalendarType.VOCATIONS) {
                //     if (managerId == dayUserId)
                //         tdClass.push('active-manager');
                //
                //     if (totalVocationDays && totalVocationDays[dayUserId] && totalVocationDays[dayUserId].exceeded)
                //         tdClass.push('exceeded');
                //     //strtotime(calendarDates[dayDate].created)*1000
                //     // console.log(date('d.m.Y H:i', new Date(calendarDates[dayDate].created)));
                //     if (calendarDates[dayDate].created)
                //         title += ' (создано '
                //             + date('d.m.Y H:i', new Date(calendarDates[dayDate].created))
                //             + '; создал: '
                //             + calendarDates[dayDate].createUserName
                //             + ')';
                // }
                // // else
                // tdClass.push('selected');
                //
                // if (users && users[dayUserId] != undefined)
                //     userName = users[dayUserId].nameShort;
                // console.dir(calendarDates[dayDate]);


                if (type == CalendarType.VOCATIONS) {

                    snoska = calendarDates[dayDate].length;

                    calendarDates[dayDate].map(dayUser => {

                        let createdInfo = '';
                        if (dayUser.createUserName)
                            createdInfo = `<span>(${dayUser.createUserName} создал ${date('d.m.Y H:i', new Date(dayUser.created))})</span>`;

                        //(id=${dayUser.userId})
                        tdContent.push(`<p>${dayUser.userName}${createdInfo}</p>`);

                        if (in_array(dayUser.userId, selectedUsersId))
                            tdClass.push('active-manager');

                        if (totalVocationDays && totalVocationDays[dayUser.userId] && totalVocationDays[dayUser.userId].exceeded)
                            tdClass.push('exceeded');
                    });
                }
                else {
                    // tdContent = calendarDates[dayDate][0].name;
                    let firstElem = calendarDates[dayDate][0];
                    // console.dir(firstElem);
                    // let createdInfo = '';
                    // if (firstElem.createUserName)
                    //     createdInfo = `<span>(${firstElem.createUserName} создал ${date('d.m.Y H:i', new Date(firstElem.created))})</span>`;

                    tdContent.push(`<p>${firstElem.name}</p>`);
                }

                tdClass.push('selected');
            }

            // if (selectedPeriod !== null) {
            // 	if (selectedPeriod.includes(dayDate)) {
            // 		tdClass.push('highlighted');
            // 	}
            // }



            row.push(
                <td
                    key={"cal-tbl-row-td" + i + j}
                    className={tdClass.join(' ')}
                    onClick={e => {
                        if (day)
                            onSelectDate(e, dayDate, selected);
                    }}

                >
                    {day}
                    <div className={"snoska"}>
                        {snoska}
                    </div>
                    {
                        tdContent.length
                            ? <div className={"pup"} dangerouslySetInnerHTML={{__html: tdContent.join('')}}></div>
                            : null
                    }
                </td>
            );
        }
        weeks.push(
            <tr key={"cal-tbl-row" + i}>
                {row}
            </tr>
        );
    }


    return (
        <div className={"calendar-month col-3 " + type}>
            <h3>
                {MONTHS[month]}
            </h3>
            {/*{month} / {totalDays} / {firstDayNum} / {totalWeeks}*/}
            <table className="tbl -light">
                <thead>
                <tr>
                    <td>
                        {DAYS_SHORT[0]}
                    </td>
                    <td>
                        {DAYS_SHORT[1]}
                    </td>
                    <td>
                        {DAYS_SHORT[2]}
                    </td>
                    <td>
                        {DAYS_SHORT[3]}
                    </td>
                    <td>
                        {DAYS_SHORT[4]}
                    </td>
                    <td>
                        {DAYS_SHORT[5]}
                    </td>
                    <td>
                        {DAYS_SHORT[6]}
                    </td>
                </tr>
                </thead>
                <tbody>
                {weeks}
                </tbody>
            </table>
        </div>
    );
}
